/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3, H4 } from '../../Universal/Title/H';
import MainContainer from '../../Universal/Container/MainContainer';
import ResourcesDetailBack from './ResourcesDetailIBack';
import ResourcesDetailInfo from './ResourcesDetailInfo';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';

const Wrapper = styled.div`
  padding-top: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    padding-top: 10rem;
  }
`;

const MetaWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const Meta = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TargetWrapper = styled.div`
`;

function ResourcesDetail({ data }) {
  const formatMeta = (input) => input.replace(/,/g, ' 、');
  return (
    <Wrapper>
      <MainContainer>
        <ResourcesDetailBack />
        <H3>{data.title}</H3>
        <MetaWrapper>
          <Meta>{`人生階段：${formatMeta(data.status)}`}</Meta>
          <Meta>{`資訊類型：${formatMeta(data.category)}`}</Meta>
          <Meta>{`戶籍地／居住地：${formatMeta(data.location)}`}</Meta>
          <Meta>{`家庭狀態：${formatMeta(data.family)}`}</Meta>
          <Meta>{`綜所稅稅率：${formatMeta(data.tax)}`}</Meta>
        </MetaWrapper>
        <ResourcesDetailInfo title="適用對象" content={data.target} />
        <ResourcesDetailInfo title="計畫內容" content={data.content} />
        <ResourcesDetailInfo title="如何申請" content={data.apply} />
        <ResourcesDetailInfo title="主辦單位（執行單位）" content={data.organizer} />
        <ResourcesDetailInfo title="資料來源" content={data.source} link />
        <ResourcesDetailInfo title="更新時間" content={data.latestupdates} />
      </MainContainer>
    </Wrapper>
  );
}

ResourcesDetail.propTypes = {
  data: PropTypes.object,
};

ResourcesDetail.defaultProps = {
  data: {},
};

export default ResourcesDetail;
