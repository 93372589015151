import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Parser } from 'html-to-react';
import { H4 } from '../../Universal/Title/H';

const Wrapper = styled.div`
  margin-bottom: 4rem;
`;

const AWrapper = styled.div`
  margin: 1rem 0;
  text-decoration: underline;
  display: block;
  word-break: break-all;
`;

function ResourcesDetailInfo({ title, content, link }) {
  if (!content || content === '') return null;

  const renderContent = () => {
    const htmlToReactParser = new Parser();
    const raw = content.replace(/\n/g, '<br />');
    return htmlToReactParser.parse(raw);
  };

  return (
    <Wrapper>
      <H4>{title}</H4>
      {link ? (
        <AWrapper>
          <a href={content} target="_blank" rel="noreferrer">
            {content}
          </a>
        </AWrapper>
      ) : (
        <p>{renderContent()}</p>
      )}
    </Wrapper>
  );
}

ResourcesDetailInfo.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.bool,
};

ResourcesDetailInfo.defaultProps = {
  title: '',
  content: '',
  link: false,
};

export default ResourcesDetailInfo;
