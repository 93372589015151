import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Arrow from '../../../assets/svg/chevron-down-solid.svg';

const Wrapper = styled.div`
  display: flex;
  padding: 0.5rem;
  border-radius: 4px;
  border: solid 1px #404a5b;
  width: 7rem;
  justify-content: center;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const Icon = styled(Arrow)`
  height: 1rem;
  width: auto;
  margin-right: 0.5rem;
  transform: rotate(90deg);
`;

const Text = styled.div`
`;

function ResourcesDetailIBack() {
  const handleBack = () => {
    navigate('/resource');
  };

  return (
    <Wrapper onClick={handleBack}>
      <Icon />
      <Text>回到上一頁</Text>
    </Wrapper>
  );
}

export default ResourcesDetailIBack;
