/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SEO from '../components/Universal/SEO';
import '../assets/css/global.css';
import '../assets/scss/style.scss';
import Header from '../components/Universal/Header/Header';
import ScrollToTop from '../components/Universal/ScrollToTop';
import { BREAK_POINT_MOBILE } from '../assets/layout';
import Contact from '../components/Contact/Contact';
import ResourcesDetail from '../components/Resources/Detail/ResourcesDetail';
import { ViewportProvider } from '../hooks/useViewport';

const Wrapper = styled.div`
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  padding-top: 50px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    padding-top: 80px;
  }
`;

const DESC = '永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。生育路上，中央與各地政府皆擬定各式資源來相挺，歡迎參考這份大補帖，找到最合適的資源！';

function ResourcesDetailLayout({ pageContext }) {
  const { data } = pageContext;
  return (
    <Wrapper id="top">
      <ViewportProvider>
        <SEO title={`${data.title} | 明日戶政所——少子女化全解析`} description={DESC} />
        <Header />
        <ContentWrapper>
          <ResourcesDetail data={data} />
          <Contact />
        </ContentWrapper>
        <ScrollToTop />
      </ViewportProvider>
    </Wrapper>
  );
}

ResourcesDetailLayout.propTypes = {
  pageContext: PropTypes.object,
};

ResourcesDetailLayout.defaultProps = {
  pageContext: {},
};

export default ResourcesDetailLayout;
